<template>
  <div class="app-container">
    <el-card class="operate-container" shadow="never">
      <i class="el-icon-tickets" style="margin-top: 5px"></i>
      <span style="margin-top: 5px">Ongoing Bulks(Captains)</span>
      <el-button class="btn-add" @click="handleCreateBulk()" size="mini">
        Create Bulk
      </el-button>
    </el-card>
    <div class="table-container">
      <el-table
        ref="table"
        style="width: 100%"
        :data="product"
        v-loading="listLoading"
        border
      >
        <el-table-column label="Product">
          <template slot-scope="scope">{{ scope.row.productName }}</template>
        </el-table-column>
        <el-table-column label="Merchant">
          <template slot-scope="scope">{{ scope.row.merchantName }}</template>
        </el-table-column>
        <el-table-column label="Groups count">
          <template slot-scope="scope">{{ scope.row.bulkTotal }}</template>
        </el-table-column>
        <el-table-column label="Orders count">
          <template slot-scope="scope">{{ scope.row.orderTotal }}</template>
        </el-table-column>
        <el-table-column label="Operation">
          <el-button type="primary" @click="showSendByProduct"
            >Send to users under ALL captains</el-button
          >
        </el-table-column>
      </el-table>
    </div>
    <div class="table-container">
      <el-table
        ref="table"
        style="width: 100%"
        :data="list"
        v-loading="listLoading"
        border
      >
        <el-table-column label="Captain Name">
          <template slot-scope="scope">{{ scope.row.captain.name }}</template>
        </el-table-column>
        <el-table-column label="Address">
          <template slot-scope="scope">{{
            scope.row.captain.address
          }}</template>
        </el-table-column>
        <el-table-column label="Orders count">
          <template slot-scope="scope">{{ scope.row.orderCount }}</template>
        </el-table-column>
        <el-table-column label="Threshold">
          <template slot-scope="scope">{{ scope.row.threshold }}</template>
        </el-table-column>
        <el-table-column label="Start">
          <template slot-scope="scope">{{ scope.row.startTime }}</template>
        </el-table-column>
        <el-table-column label="End">
          <template slot-scope="scope">{{ scope.row.endTime }}</template>
        </el-table-column>
        <el-table-column label="Status">
          <template slot-scope="scope">{{ scope.row.bulkStatus }}</template>
        </el-table-column>
        <el-table-column label="Operations">
          <template slot-scope="scope">
            <p>
              <el-button size="mini" @click="showDetail(scope.row)"
                >Details</el-button
              >
            </p>
            <p>
              <el-button size="mini" @click="enableBulk(scope.row.id)"
                >Force Enable</el-button
              >
            </p>
            <p>
              <el-button
                size="mini"
                type="danger"
                @click="handleEdit(scope.row)"
                >Edit</el-button
              >
            </p>
            <p>
              <el-button size="mini" type="primary" @click="showSendByBulk(scope.row)"
                >Send Notification</el-button
              >
            </p>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog :visible.sync="dialogVisible" :title="dialogTitle">
        <el-form>
          <el-form-item label="Title">
            <el-input v-model="notificationTitle" auto-complete="off">
            </el-input>
          </el-form-item>
          <el-form-item label="Body">
            <el-input
              v-model="notificationBody"
              auto-complete="off"
              :rows="3"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="dialogVisible=false;dialogTitle=null;">Cancel</el-button>
            <el-button type="primary" @click="submitSendNotification">Submit</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
  fetchOngoingByProduct,
  forceEnable as forceEnableBulk,
} from '@/api/bulk';
import moment from 'moment';
import { sendAllByOngoingProduct, sendAllByBulk } from '@/api/notification';

const weekdays = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
};
export default {
  name: 'ongoingBulk',
  created() {
    this.getList();
  },
  data() {
    return {
      list: null,
      listLoading: true,
      product: null,
      dialogVisible: false,
      dialogTitle: null,
      notificationTitle: null,
      notificationBody: null,
      notificationBulkId: null,
    };
  },
  methods: {
    getList() {
      if (this.$route.query.id == null) return false;
      fetchOngoingByProduct(this.$route.query.id).then((response) => {
        this.list = response.data.data;
        this.product = [response.data];
        this.listLoading = false;
      });
    },
    showDetail(row) {
      this.$router.push({
        path: '/bulkService/OngoingOrder',
        query: { id: row.id },
      });
    },
    handleCreateBulk() {
      this.$router.push({
        path: '/bulkService/CreateBulk',
        query: {
          productId: this.product[0].productId,
          productName: this.product[0].productName,
          startTime: this.list.length > 0 ? this.list[0].startTime : null,
          endTime:
            this.list.length > 0 ? this.list[0].endTime : this.getNextEndTime(),
        },
      });
    },
    handleEdit(bulk) {
      this.$router.push({
        path: '/bulkService/EditBulk',
        query: {
          id: bulk.id,
          productDisplay: this.product[0].productName,
          captainDisplay: bulk.captain.name,
          startTime: bulk.startTime,
          endTime: bulk.endTime,
          thr: bulk.threshold,
          max: bulk.memberLimit,
        },
      });
    },
    enableBulk(bulkId) {
      this.$confirm('Force enable the bulk of this order?', 'Warning', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }).then(() => {
        forceEnableBulk(bulkId).then(() => {
          this.$message({
            message: 'Success',
            type: 'success',
            duration: 1000,
          });
          this.getList();
        });
      });
    },
    getNextEndTime() {
      const day = weekdays[this.product[0].merchantEndDay];
      const d = new Date();
      d.setDate(d.getDate() + ((day + 7 - d.getDay()) % 7));
      d.setHours(16, 0, 0);
      const formatted = moment(d).format('yyyy-MM-DD HH:mm:ss');
      return formatted;
    },
    showSendByProduct() {
      this.dialogTitle = 'Send to users under ALL captains';
      this.dialogVisible = true;
    },
    showSendByBulk(bulk) {
      this.dialogTitle = 'Send to Bulk Community';
      this.notificationBulkId = bulk.id;
      this.dialogVisible = true;
    },
    submitSendNotification() {
      const param = {
        title: this.notificationTitle,
        body: this.notificationBody,
        id: null,
      };
      if (this.dialogTitle == 'Send to users under ALL captains') {
        param.id = this.product[0].productId;
        sendAllByOngoingProduct(param).then(this.showSuccess);
      } else if (this.dialogTitle == 'Send to Bulk Community') {
        param.id = this.notificationBulkId;
        sendAllByBulk(param).then(this.showSuccess);
      }
    },
    showSuccess() {
      this.$message({
        message: 'Success',
        type: 'success',
        duration: 1000,
      });
      this.dialogVisible = false;
    },
  },
};
</script>
